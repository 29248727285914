<template>
  <BannerBase background="bg-static-default-mid" :isBusy="isBusy">
    <div ref="messagingPlaceholder" class="size-full"></div>
  </BannerBase>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import type { Price } from '@backmarket/http-api'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { usePaypalLibrary } from '../../composables/usePaypalLibrary'
import BannerBase from '../BannerBase/BannerBase.vue'

const messagingPlaceholder = ref<HTMLElement | null>(null)
const isBusy = ref(true)

const props = defineProps<{
  paymentMethod: PaymentMethod
  basePrice: Price
}>()

const { market } = useMarketplace()

const paypal = usePaypalLibrary(props.paymentMethod)

onMounted(async () => {
  const mountedTime = window.performance.now()

  if (!messagingPlaceholder.value) {
    return
  }

  const message = await paypal.Messages({
    amount: parseFloat(props.basePrice.amount),
    currency: props.basePrice.currency,
    buyerCountry: market.countryCode,
    onRender: () => {
      isBusy.value = false
    },
    style: {
      layout: 'text',
      text: {
        size: '14',
      },
      logo: {
        type: 'alternative',
      },
    },
  })

  // welcome to the hack zone (:
  // the paypal lib check the element boundary to make sure the element is within a visible area
  // unfortunately, during the accordion transition, the element is not visible
  // this transition is 300ms long
  // sadly putting a timeout of 300ms is not enough and messes up the animation FPS
  const animationDuration = 320
  await new Promise((resolve) => {
    const timePassed = window.performance.now() - mountedTime
    if (timePassed >= animationDuration) {
      resolve(0)
    } else {
      setTimeout(resolve, animationDuration - timePassed)
    }
  })

  message.render(messagingPlaceholder.value)
})
</script>
