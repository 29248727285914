<template>
  <BannerBase background="bg-[#b2fce4]/20" :isBusy="isBusy">
    <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
    <square-placement v-bind="placementAttributes" />
  </BannerBase>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { Price } from '@backmarket/http-api/src/api-models/Price'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toRfc4646Locale } from '@backmarket/utils/string/toRfc4646Locale'

import { isValidSquarePaymentMethodConfig } from '../../../form-common/helpers/isValidConfig'
import { useSquareLibrary } from '../../composables/useSquareLibrary'
import BannerBase from '../BannerBase/BannerBase.vue'

const props = defineProps<{
  paymentMethod: PaymentMethod
  basePrice: Price
}>()

const isBusy = ref(true)
const { market } = useMarketplace()
const locale = computed(() => toRfc4646Locale(market.defaultLocale))

const { onLoaded } = useSquareLibrary(props.paymentMethod)
onLoaded(() => {
  // TODO: is there a way to know when the square banner is rendered?
  isBusy.value = false
})

const placementAttributes = computed(() => {
  const { config } = props.paymentMethod
  if (!isValidSquarePaymentMethodConfig(config)) {
    return {}
  }

  return {
    'data-mpid': config.mpid,
    'data-placement-id': config.placementId,
    'data-page-type': 'cart',
    'data-amount': props.basePrice.amount,
    'data-currency': props.basePrice.currency,
    'data-consumer-locale': locale.value,
    'data-cart-is-eligible': 'true',
  }

  // FYI: Undocumented styling options
  //     'data-size': 'sm',
  //     'data-logo-type': 'compact-badge',
  //     'data-intro-text': 'Pay',
})
</script>
