<!-- eslint-disable tailwindcss/no-custom-classname -->
<!-- The use of "affirm-as-low-as" is required -->
<template>
  <BannerBase background="bg-[#0FA0EA1A]" :is-busy="isBusy">
    <p
      class="affirm-as-low-as"
      :data-amount="purchaseAmount"
      data-page-type="payment"
    ></p>
  </BannerBase>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { Price } from '@backmarket/http-api'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { priceToMinorUnits } from '@backmarket/utils/math/priceToMinorUnits'

import { useAffirmLibrary } from '../../composables/useAffirmLibrary'
import BannerBase from '../BannerBase/BannerBase.vue'

const props = defineProps<{
  paymentMethod: PaymentMethod
  basePrice: Price
}>()

const isBusy = ref(true)
const purchaseAmount = computed(() => priceToMinorUnits(props.basePrice))

const { onLoaded } = useAffirmLibrary()
onLoaded(() => {
  isBusy.value = false
})
</script>
