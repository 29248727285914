<template>
  <BannerBase background="bg-[#FF98C1]/20" :is-busy="isBusy">
    <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
    <klarna-placement
      data-key="credit-promotion-auto-size"
      :data-locale="locale"
      :data-purchase-amount="purchaseAmount"
    >
    </klarna-placement>
  </BannerBase>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { Price } from '@backmarket/http-api'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { priceToMinorUnits } from '@backmarket/utils/math/priceToMinorUnits'
import { toRfc4646Locale } from '@backmarket/utils/string/toRfc4646Locale'

import { useKlarnaLibrary } from '../../composables/useKlarnaLibrary'
import BannerBase from '../BannerBase/BannerBase.vue'

const isBusy = ref(true)

const props = defineProps<{
  paymentMethod: PaymentMethod
  basePrice: Price
}>()

const { onLoaded } = useKlarnaLibrary(props.paymentMethod)
onLoaded(async (lib) => {
  // klarna script load in two phases.
  // The first one is the script tag that trigger this onLoaded callback.
  // Then the script will load the OnsiteMessaging script object.
  // There is no event to know when the OnsiteMessaging script is loaded.
  // But as klarna uses web components, we can wait for the klarna-placement custom element to be defined.
  const BUSY_TIMEOUT = 5000

  // safety net in case the Klarna API change
  const timeout = setTimeout(() => {
    isBusy.value = false
  }, BUSY_TIMEOUT)

  const toggleBusyOnPlacementRendered = () => {
    lib.OnsiteMessaging?.on('placement-rendered', () => {
      isBusy.value = false
      if (timeout) {
        clearTimeout(timeout)
      }
    })
  }

  if ('OnsiteMessaging' in lib) {
    toggleBusyOnPlacementRendered()
  } else {
    await window.customElements.whenDefined('klarna-placement')
    toggleBusyOnPlacementRendered()
  }
})

const { market } = useMarketplace()
const locale = computed(() => toRfc4646Locale(market.defaultLocale))
const purchaseAmount = computed(() => priceToMinorUnits(props.basePrice))
</script>

<style scoped>
klarna-placement::part(osm-container) {
  background-color: transparent;
  font-size: inherit;
  border: none;
  padding: 0;
  text-align: left; /* to be consistent with the paypal banner */
}
</style>
