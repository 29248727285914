import {
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
  readonly,
  ref,
} from 'vue'

export function useActivated() {
  const activated = ref(false)

  function activate() {
    activated.value = true
  }
  function deactivate() {
    activated.value = false
  }

  onActivated(activate)
  onDeactivated(deactivate)

  // Use onMounted and onUnmounted to ensure that the activated state is correctly set.
  // If the state is not under keep-alive component, the onActivated and onDeactivated hooks will not be called.
  onMounted(activate)
  onUnmounted(deactivate)

  return readonly(activated)
}
