<template>
  <div>
    <PaypalBanner
      v-if="props.paymentMethod"
      :base-price="props.basePrice"
      class="mb-12"
      :payment-method="props.paymentMethod"
    />

    {{ i18n(translations.body) }}

    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import PaypalBanner from '../../../../advertising/components/PaypalBanner/PaypalBanner.vue'
import type { PaymentFormAdornmentProps } from '../PaymentFormAdornment.type'

import translations from './PaypalFormAdornment.translations'

const i18n = useI18n()

const props = defineProps<PaymentFormAdornmentProps>()
</script>
