<template>
  <div class="caption mt-4 flex items-center justify-center">
    <IconLockLocked class="mr-4 h-16 w-16 self-baseline" />

    {{ i18n(translations.securePayment) }}
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconLockLocked } from '@ds/icons/IconLockLocked'

import translations from './SecurityReassuranceCaption.translations'

const i18n = useI18n()
</script>
