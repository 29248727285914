import { type MaybeRefOrGetter, toRaw, toValue } from 'vue'

import type { PaymentMethodConfig } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useScript } from '@unhead/vue'
import { createSharedComposable } from '@vueuse/core'

import type {
  GooglePay,
  GooglePayIsReadyToPayRequest,
  GooglePayPaymentDataRequest,
  GooglePayPaymentsClient,
  GooglePayTransactionInfo,
} from '../../../../types/google-pay'
import { PaymentError } from '../../form-common'
import { isValidGooglePayPaymentMethodConfig } from '../../form-common/helpers/isValidConfig'
import { PaymentMethodMisconfiguredError } from '../../form-common/types/PaymentMethodMisconfiguredError'

const GOOGLE_PAY_LIBRARY_URL = 'https://pay.google.com/gp/p/js/pay.js'

const BASE_REQUEST = {
  apiVersion: 2,
  apiVersionMinor: 0,
}

const useGooglePayScript = () => {
  return useScript<GooglePay>(
    {
      src: GOOGLE_PAY_LIBRARY_URL,
      crossorigin: false,
    },
    {
      use: () => window.google,
    },
  )
}

export const useGooglePay = createSharedComposable(
  (config: MaybeRefOrGetter<PaymentMethodConfig>) => {
    const googlePayScript = useGooglePayScript()

    function rawConfig() {
      const raw = toRaw(toValue(config))

      if (isValidGooglePayPaymentMethodConfig(raw)) {
        return raw
      }

      throw new PaymentMethodMisconfiguredError(
        'Invalid Google Pay configuration',
      )
    }

    let paymentsClient: GooglePayPaymentsClient
    async function getPaymentsClient() {
      if (!paymentsClient) {
        const { environment } = rawConfig()
        const library = await googlePayScript

        paymentsClient = new library.payments.api.PaymentsClient({
          environment,
        })
      }

      return paymentsClient
    }

    function getIsReadyToPayRequest(): GooglePayIsReadyToPayRequest {
      const { type, parameters } = rawConfig()

      return {
        ...BASE_REQUEST,
        allowedPaymentMethods: [{ type, parameters }],
      }
    }

    function getPaymentDataRequest(
      transactionInfo: GooglePayTransactionInfo,
    ): GooglePayPaymentDataRequest {
      const { type, parameters, tokenizationSpecification, merchantInfo } =
        rawConfig()

      return {
        ...BASE_REQUEST,
        transactionInfo,
        merchantInfo,
        allowedPaymentMethods: [
          { type, parameters, tokenizationSpecification },
        ],
      }
    }

    async function isReady() {
      const client = await getPaymentsClient()
      const request = getIsReadyToPayRequest()

      const { result } = await client.isReadyToPay(request)

      if (!result) {
        throw new PaymentError('Google Pay is not ready to pay', {
          type: '/errors/payment/unexpected/google-pay-not-ready',
        })
      }
    }

    return {
      isReady,
      getPaymentsClient,
      getIsReadyToPayRequest,
      getPaymentDataRequest,
    }
  },
)
