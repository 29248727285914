import type { MarketCountryCode } from '@backmarket/http-api'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment'

import { useScript } from './useScript'

export type OneyFilter = {
  payment_method: string
  payment_method_type: string
  payment_mode: string
  is_free: string
  with_down_payment: string
}

export type OneyOptions = {
  country: MarketCountryCode
  language: MarketCountryCode
  merchant_guid: string
  payment_amount: string
  filter_by: string
  filters: OneyFilter[]
  hide_logo: boolean
  checkout_placeholder: string
}

declare global {
  interface Window {
    loadOneyWidget: (config: () => void) => void
    oneyMerchantApp: {
      loadCheckoutSection: (args: { options: OneyOptions }) => void
    }
  }
}

export const ONEY_LIBRARY_URL = 'https://assets.oney.io/build/loader.min.js'

export const useOneyLibrary = (method: PaymentMethod) => {
  return useScript(
    'libraryUrl' in method.config && method.config.libraryUrl
      ? method.config.libraryUrl
      : ONEY_LIBRARY_URL,
  )
}
