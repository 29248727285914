<template><div></div></template>

<script setup lang="ts">
import { h, onMounted, ref } from 'vue'

import { paymentAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import {
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterExpose,
  type PaymentFormAdapterProps,
  PaymentSetupError,
  PaymentSubmitError,
} from '../../../form-common'
import { getRedirectionToPaymentResult } from '../../../form-common/helpers/getRedirection'
import { useGooglePay } from '../../composables/useGooglePay'

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

const googlePay = useGooglePay(() => props.paymentMethod?.config ?? {})
const marketplace = useMarketplace()

const googlePayButtonContainer = ref()

async function onGooglePaymentButtonClicked() {
  emit('submit-start')

  const paymentDataRequest = googlePay.getPaymentDataRequest({
    currencyCode: props.basePrice.currency,
    countryCode: marketplace.market.countryCode,
    totalPrice: props.basePrice.amount,
    totalPriceStatus: 'FINAL',
  })

  try {
    const paymentsClient = await googlePay.getPaymentsClient()
    const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest)

    const { paymentId } = await props.createPayment({})

    await $httpFetch(paymentAPI.postConfirmHpp, {
      pathParams: { paymentId },
      body: {
        gatewayToken: paymentData.paymentMethodData.tokenizationData.token,
      },
    })

    emit('submit-success', {
      paymentId,
      redirection: getRedirectionToPaymentResult(paymentId),
    })
  } catch (err) {
    emit('submit-error', PaymentSubmitError.fromAnyError(err))
  }
}

async function addGooglePayButton() {
  const button = (await googlePay.getPaymentsClient()).createButton({
    onClick: onGooglePaymentButtonClicked,
    buttonSizeMode: 'fill',
  })

  googlePayButtonContainer.value.appendChild(button)
}

async function prefetchGooglePaymentData() {
  const paymentDataRequest = googlePay.getPaymentDataRequest({
    currencyCode: props.basePrice.currency,
    countryCode: marketplace.market.countryCode,
    totalPrice: props.basePrice.amount,
    totalPriceStatus: 'ESTIMATED',
  })

  const paymentsClient = await googlePay.getPaymentsClient()
  paymentsClient.prefetchPaymentData(paymentDataRequest)
}

onMounted(async () => {
  emit('setup-start')

  try {
    await googlePay.isReady()
    await Promise.all([addGooglePayButton(), prefetchGooglePaymentData()])

    emit('setup-success')
  } catch (err) {
    emit('setup-error', PaymentSetupError.fromAnyError(err))
  }
})

defineExpose<PaymentFormAdapterExpose>({
  submitButton: {
    is: {
      setup() {
        return () =>
          h('div', {
            ref: googlePayButtonContainer,
            class: 'h-48 w-full min-w-80 md:w-auto',
          })
      },
    },
  },
})
</script>
