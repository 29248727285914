import { shallowReactive } from 'vue'

import { noop } from '@backmarket/utils/function/noop'

import { VirtualCardFieldIds } from '../types/VirtualCardFieldIds'
import type {
  VirtualCardFieldProps,
  VirtualCardFormState,
} from '../types/VirtualCardFormState'

type FormActions = {
  focus?: (field: VirtualCardFieldIds) => void
}

const buildVirtualField = <FieldId extends VirtualCardFieldIds>(
  id: FieldId,
  actions: FormActions,
): VirtualCardFieldProps<FieldId> =>
  shallowReactive({
    id,
    filled: false,
    focused: false,
    invalid: false,
    touched: false,
    error: undefined,
    lastAutofillValue: undefined,
    onRequestFocus: actions.focus?.bind(null, id) ?? noop,
  })

export const buildVirtualFormState = (
  formActions: FormActions = {},
): VirtualCardFormState => {
  return {
    securityCode: buildVirtualField('securityCode', formActions),
    expiryDate: buildVirtualField('expiryDate', formActions),
    number: buildVirtualField('number', formActions),
    name: buildVirtualField('name', formActions),
  }
}
